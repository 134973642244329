import React, { FC } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { Routes } from "@routes";
import { Localize } from "@components/atoms/Localize";
import { stylesWrapper, wysiwygStyles } from "@theme/commonStyles";
import { breakpoints } from "@theme/breakpoints";
import { BackButton } from "@components/atoms/BackButton";
import { useLocation } from "@reach/router";
import { useTranslation } from "gatsby-plugin-react-i18next";
import HtmlParser from "react-html-parser";
import { stripHtml } from "@utils/stripHtml";

const StyledWrapper = styled.div`
  ${stylesWrapper};
  margin-top: 220px;

  ${breakpoints.tabletMax`
    max-width: 800px;
    margin-top: 180px;
  `}

  ${breakpoints.phoneMax`
    max-width: 800px;
    margin-top: 140px;
  `}
`;

const StyledBackButton = styled(BackButton)`
  margin-bottom: 20px;
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  ${breakpoints.tabletMax`
   flex-direction: column;
    margin: 40px auto 0;
  `}
`;

const StyledLeftWrapper = styled.div`
  max-width: 600px;
  flex: 1;

  ${breakpoints.laptopMax`
    max-width: 500px;
  `}

  ${breakpoints.tabletMax`
    width: 100%;
    max-width: unset;
  `}
`;

const StyledTitle = styled.h1`
  margin: 0 0 30px;
  font-size: 4.8rem;
  font-weight: 700;

  span {
    color: ${({ theme }) => theme.primary};
  }

  ${breakpoints.laptopMax`
    font-size: 3.6rem;
  `}

  ${breakpoints.phoneMax`
    font-size: 3.4rem;
    margin: 0 0 20px;
  `}
`;

const StyledShortDescription = styled.p`
  margin: 0;
  font-size: 1.8rem;
  ${wysiwygStyles};
  line-height: 1.8;

  :first-of-type {
    margin-bottom: 20px;
  }

  ${breakpoints.laptopMax`
    font-size: 1.6rem;
  `}

  ${breakpoints.tabletMax`
    max-width: 800px;
  `}
`;

const StyledImageWrapper = styled.div`
  width: 700px;
  height: 510px;
  position: relative;
  margin-left: 50px;

  ${breakpoints.laptopMax`
    width: 650px;
    height: 480px;
  `}

  ${breakpoints.tabletMax`
    width: 100%;
    height: 60vw;
    margin-left: 0;
    margin-top: 20px;
    max-height: 500px;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  border-radius: 30px;
  width: 100%;
  height: 100%;
`;

const StyledVideo = styled.iframe`
  width: 100%;
  height: 100%;
  border-radius: 30px;
  border: 0;
`;

export const UniversalHero: FC<Props> = ({
  title,
  description,
  image,
  video,
  videoArguments,
}) => {
  const { state } = useLocation();
  const { t } = useTranslation();

  return (
    <StyledWrapper>
      <StyledBackButton
        to={
          (state as any)?.from?.includes(Routes.Offer)
            ? Routes.Offer
            : Routes.Home
        }
      >
        {(state as any)?.from?.includes(Routes.Offer)
          ? t("common:back-button-offer")
          : t("common:back-button")}
      </StyledBackButton>
      <Localize pageNames={[t("navigation:list.offer"), stripHtml(title)]} />

      <StyledInnerWrapper>
        <StyledLeftWrapper>
          <StyledTitle>{HtmlParser(title)}</StyledTitle>
          {description.map((item) => (
            <StyledShortDescription key={item}>
              {HtmlParser(item)}
            </StyledShortDescription>
          ))}
        </StyledLeftWrapper>

        <StyledImageWrapper>
          {image && <StyledImage image={getImage(image)} alt={title} />}
          {video && <StyledVideo src={video} {...videoArguments} />}
        </StyledImageWrapper>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

interface Props {
  image?: ImageDataLike;
  video?: string;
  title: string;
  description: string[];
  videoArguments?: {
    [key: string]: string;
  };
}
