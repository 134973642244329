import React, { FC } from "react";
import styled from "styled-components";
import arrowRight from "@iconify/icons-bi/arrow-right-short";
import { Icon } from "@iconify/react";
import { Link } from "gatsby-plugin-react-i18next";
import { breakpoints } from "@theme/breakpoints";

const StyledWrapper = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 500;
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
  width: fit-content;

  svg {
    font-size: 2.5rem;
    margin-left: 10px;
  }

  :hover {
    text-decoration: underline;
  }

  ${breakpoints.laptopMax`
    font-size: 1.6rem;
  `}
`;

export const SeeMoreButton: FC<Props> = ({ children, to, className }) => (
  <StyledWrapper to={to} className={className}>
    <>
      {children} <Icon icon={arrowRight} />
    </>
  </StyledWrapper>
);

interface Props {
  to: string;
  className?: string;
}
