import React, { FC } from "react";
import { OfferTemplate } from "@templates/OfferTemplate";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import { graphql } from "gatsby";
import { TwoColumnsSection } from "@components/atoms/TwoColumnsSection";
import { UniversalSection } from "@components/molecules/UniversalSection";
import styled from "styled-components";
import { SectionHeadline } from "@components/atoms/SectionHeadline";
import { breakpoints } from "@theme/breakpoints";
import HtmlParser from "react-html-parser";
import { stylesWrapper } from "@theme/commonStyles";
import { Routes } from "@routes";
import WhatWeCanDoMore from "@components/organisms/WhatWeCanDoMore";

const StyledSectionHeadline = styled(SectionHeadline)`
  ${stylesWrapper};
  margin-top: 140px;
  margin-bottom: 0;

  ${breakpoints.tabletMax`
    margin-top: 100px;
  `}

  ${breakpoints.phoneMax`
    margin-top: 80px;
  `}
`;

const Visualizations: FC<Props> = ({ data }) => {
  const { t } = useTranslation("offer");
  const { t: ct } = useTranslation("common");

  return (
    <OfferTemplate
      title={ct("title-offer-visualizations")}
      description={ct("description-offer-visualizations")}
      heroTitle={t("visualizations.hero.title")}
      heroDescription={t("visualizations.hero.description", {
        returnObjects: true,
      })}
      heroImage={data.heroImage.childImageSharp.gatsbyImageData}
    >
      <TwoColumnsSection
        leftColumn={t("visualizations.sections.0.firstColumn", {
          returnObjects: true,
        })}
        rightColumn={t("visualizations.sections.0.secondColumn", {
          returnObjects: true,
        })}
      />
      <UniversalSection
        title={t("visualizations.sections.1.title")}
        description={t("visualizations.sections.1.description", {
          returnObjects: true,
        })}
        images={data.exteriorImages.exterior.map(
          (exteriorImage) =>
            exteriorImage.localFile.childImageSharp.gatsbyImageData
        )}
        galleryId={0}
      />

      <UniversalSection
        title={t("visualizations.sections.2.title")}
        description={t("visualizations.sections.2.description", {
          returnObjects: true,
        })}
        images={data.interiorImages.interior.map(
          (interiorImage) =>
            interiorImage.localFile.childImageSharp.gatsbyImageData
        )}
        fromRight
        galleryId={1}
        btnText={t("see-more")}
        btnTo={Routes.InteriorVisualizations}
        btnType="unframed"
      />

      <UniversalSection
        title={t("visualizations.sections.7.title")}
        description={t("visualizations.sections.7.description", {
          returnObjects: true,
        })}
        images={data.estateAgentsGallery.nodes.map(
          (image) => image.childImageSharp.gatsbyImageData
        )}
        btnText="Kontakt"
        btnTo={Routes.Contact}
        btnType="framed"
        galleryId={2}
      />

      <StyledSectionHeadline>
        {HtmlParser(t("visualizations.sections.why-worth"))}
      </StyledSectionHeadline>

      <UniversalSection
        title={t("visualizations.sections.3.title")}
        description={[t("visualizations.sections.3.description")]}
        images={[data.firstSectionImage.childImageSharp.gatsbyImageData]}
        btnText={t("see-realizations")}
        btnTo={Routes.RealisedProjects}
      />

      <UniversalSection
        title={t("visualizations.sections.4.title")}
        description={t("visualizations.sections.4.description", {
          returnObjects: true,
        })}
        images={[data.secondSectionImage.childImageSharp.gatsbyImageData]}
        fromRight
        btnText={t("see-realizations")}
        btnTo={Routes.RealisedProjects}
      />

      <UniversalSection
        title={t("visualizations.sections.5.title")}
        description={[t("visualizations.sections.5.description")]}
        images={[data.thirdSectionImage.childImageSharp.gatsbyImageData]}
        btnText={t("see-realizations")}
        btnTo={Routes.RealisedProjects}
      />

      <UniversalSection
        title={t("visualizations.sections.6.title")}
        description={[t("visualizations.sections.6.description")]}
        images={[data.fourthSectionImage.childImageSharp.gatsbyImageData]}
        fromRight
        btnText={t("see-realizations")}
        btnTo={Routes.RealisedProjects}
      />

      <WhatWeCanDoMore exclude={1} />
    </OfferTemplate>
  );
};

interface Props {
  data: {
    heroImage: ChildImageSharp;
    exteriorImages: {
      exterior: {
        localFile: ChildImageSharp;
      }[];
    };
    interiorImages: {
      interior: {
        localFile: ChildImageSharp;
      }[];
    };
    estateAgentsGallery: {
      nodes: ChildImageSharp[];
    };
    firstSectionImage: ChildImageSharp;
    secondSectionImage: ChildImageSharp;
    thirdSectionImage: ChildImageSharp;
    fourthSectionImage: ChildImageSharp;
  };
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    heroImage: file(name: { eq: "offer_visualizations_1" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    exteriorImages: strapiVisualization {
      exterior {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80)
          }
        }
      }
    }

    interiorImages: strapiVisualization {
      interior {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 80)
          }
        }
      }
    }

    estateAgentsGallery: allFile(
      filter: { name: { regex: "/offer-visualizations-gallery_/" } }
      sort: { fields: [name], order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 80)
        }
      }
    }

    firstSectionImage: file(name: { eq: "offer_visualizations_2" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    secondSectionImage: file(name: { eq: "exterior_7" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    thirdSectionImage: file(name: { eq: "offer_visualizations_3" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }

    fourthSectionImage: file(name: { eq: "exterior_6" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }
  }
`;

export default Visualizations;
