import React, { FC } from "react";
import { BasicTemplate } from "@templates/BasicTemplate";
import { ReviewsSection } from "@components/molecules/ReviewsSection";
import { Contact } from "@components/molecules/Contact";
import { UniversalHero } from "@components/molecules/UniversalHero";
import { ImageDataLike } from "gatsby-plugin-image";

export const OfferTemplate: FC<Props> = ({
  title,
  description,
  children,
  heroDescription,
  heroImage,
  heroTitle,
  heroVideo,
  heroVideoArguments,
}) => {
  return (
    <BasicTemplate
      title={title}
      description={description}
      renderHero={() => (
        <UniversalHero
          title={heroTitle}
          description={heroDescription}
          image={heroImage}
          video={heroVideo}
          videoArguments={heroVideoArguments}
        />
      )}
    >
      {children}
      <ReviewsSection secondary />
      <Contact />
    </BasicTemplate>
  );
};

interface Props {
  title: string;
  description: string;
  heroTitle: string;
  heroDescription: string[];
  heroVideo?: string;
  heroImage?: ImageDataLike;
  heroVideoArguments?: {
    [key: string]: string;
  };
}
