import React, { FC } from "react";
import styled from "styled-components";
import { stylesWrapper } from "@theme/commonStyles";
import { OfferCard } from "@components/atoms/OfferCard";
import { breakpoints } from "@theme/breakpoints";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Routes } from "@routes";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import { graphql, useStaticQuery } from "gatsby";

const StyledWrapper = styled.div`
  ${stylesWrapper};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledOfferCard = styled(OfferCard)`
  margin-bottom: 60px;

  ${breakpoints.tabletMax`
    margin-bottom: 50px;
  `}

  ${breakpoints.phoneMax`
    margin-bottom: 30px;
  `}
`;

export const AllOfferCards: FC<Props> = ({ exclude }) => {
  const { t } = useTranslation();
  const data = useStaticQuery<Data>(query);

  const cards: string[] = t("offer:items", { returnObjects: true });

  const cardProps = [
    {
      image: data.investmentWebsite.childImageSharp.gatsbyImageData,
      to: Routes.InvestmentWebsite,
    },
    {
      image: data.visualizations.childImageSharp.gatsbyImageData,
      to: Routes.Visualizations3D,
    },
    {
      image: data.developerWebsite.childImageSharp.gatsbyImageData,
      to: Routes.DeveloperWebsite,
    },
    {
      image: data.marketingCampaigns.childImageSharp.gatsbyImageData,
      to: Routes.MarketingCampaigns,
    },
    {
      image: data.interiorVisualizations.childImageSharp.gatsbyImageData,
      to: Routes.InteriorVisualizations,
    },
    {
      image: data.animations3d.childImageSharp.gatsbyImageData,
      to: Routes.Animations3D,
    },
    {
      image: data.floor3DPlanVisualizations.childImageSharp.gatsbyImageData,
      to: Routes.Floor3DPlan,
    },
    {
      image: data.printableMarketingMaterials.childImageSharp.gatsbyImageData,
      to: Routes.PropertyCard,
    },
    {
      image: data.virtualTour.childImageSharp.gatsbyImageData,
      to: Routes.VirtualTour,
    },
    {
      image: data.constructionCompanyWebsite.childImageSharp.gatsbyImageData,
      to: Routes.ConstructionCompanyWebsite,
    },
  ];

  return (
    <StyledWrapper>
      {cards
        .filter((_, index) => index !== exclude)
        .map((title, index) => (
          <StyledOfferCard
            title={title}
            key={index}
            {...cardProps.filter((_, index) => index !== exclude)[index]}
          />
        ))}
    </StyledWrapper>
  );
};

interface Props {
  exclude?: number;
}

interface Data {
  investmentWebsite: ChildImageSharp;
  visualizations: ChildImageSharp;
  interiorVisualizations: ChildImageSharp;
  developerWebsite: ChildImageSharp;
  marketingCampaigns: ChildImageSharp;
  printableMarketingMaterials: ChildImageSharp;
  animations3d: ChildImageSharp;
  floor3DPlanVisualizations: ChildImageSharp;
  virtualTour: ChildImageSharp;
  constructionCompanyWebsite: ChildImageSharp;
}

const query = graphql`
  {
    investmentWebsite: file(name: { eq: "offer_investment-website_1" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    visualizations: file(name: { eq: "offer_visualizations" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    interiorVisualizations: file(name: { eq: "interior-visualization_card" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    developerWebsite: file(name: { eq: "offer_developer-website" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED)
      }
    }

    marketingCampaigns: file(name: { eq: "offer_marketing-campaigns" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, placeholder: BLURRED)
      }
    }

    animations3d: file(name: { eq: "offer-3d-animations_card" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, placeholder: BLURRED)
      }
    }

    printableMarketingMaterials: file(
      name: { eq: "printable-marketing-materials_hero" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 95, placeholder: BLURRED)
      }
    }

    floor3DPlanVisualizations: file(
      name: { eq: "3d-floor-plan-visualizations_card" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 95, placeholder: BLURRED)
      }
    }

    virtualTour: file(name: { eq: "offer-virtual-tour_card" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, placeholder: BLURRED)
      }
    }

    constructionCompanyWebsite: file(
      name: { eq: "construction-company-website_hero" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 95, placeholder: BLURRED)
      }
    }
  }
`;
