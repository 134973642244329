import React, { FC } from "react";
import { AllOfferCards } from "@components/molecules/AllOfferCards";
import styled from "styled-components";
import { SectionHeadline } from "@components/atoms/SectionHeadline";
import { breakpoints } from "@theme/breakpoints";
import { useTranslation } from "gatsby-plugin-react-i18next";

const StyledSectionHeadline = styled(SectionHeadline)`
  text-align: center;
  margin: 140px auto 100px auto;
  max-width: 90%;

  ${breakpoints.tabletMax`
    margin-top: 120px;
    margin-bottom: 80px;
  `}

  ${breakpoints.phoneMax`
    margin-top: 100px;
    margin-bottom: 60px;
  `};

  @media (max-width: 480px) {
    margin-bottom: 40px;
    margin-top: 80px;
  }
`;

const WhatWeCanDoMore: FC<Props> = ({ exclude }) => {
  const { t } = useTranslation("offer");

  return (
    <section>
      <StyledSectionHeadline
        dangerouslySetInnerHTML={{
          __html: t("what-we-can-do"),
        }}
      />
      <AllOfferCards exclude={exclude} />
    </section>
  );
};

interface Props {
  exclude: number;
}

export default WhatWeCanDoMore;
