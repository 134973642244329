import React, { FC } from "react";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import styled, { css } from "styled-components";
import { stylesWrapper, wysiwygStyles } from "@theme/commonStyles";
import { breakpoints } from "@theme/breakpoints";
import HtmlParser from "react-html-parser";
import { SeeMoreButton } from "@components/atoms/SeeMoreButton";
import { Gallery } from "@components/atoms/Gallery";
import { Button } from "@components/atoms/Button";
import { Link } from "gatsby";

const StyledWrapper = styled.section<{ $fromRight?: true }>`
  ${stylesWrapper};
  margin-top: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ $fromRight }) =>
    $fromRight &&
    css`
      flex-direction: row-reverse;
    `}

  ${breakpoints.tabletMax`
    flex-direction: column;
    max-width: 800px;
    margin-top: 100px;
  `}

  ${breakpoints.phoneMax`
    margin-top: 80px;
  `}
`;

const StyledLeftWrapper = styled.div<{ $fromRight?: true }>`
  flex: 1;
  max-width: 550px;
  margin-right: 50px;

  ${({ $fromRight }) =>
    $fromRight &&
    css`
      margin-right: 0;
      margin-left: 50px;
    `}

  ${breakpoints.tabletMax`
    max-width: unset;
    margin-left: unset;
    margin-right: unset;
  `}
`;

const StyledRightWrapper = styled.div`
  width: 700px;
  height: 510px;
  border-radius: 50px;

  .swiper,
  iframe,
  .gatsby-image-wrapper {
    border-radius: 50px;
    overflow: hidden;
    isolation: isolate;
  }

  ${breakpoints.laptopMax`
    width: 650px;
    height: 480px;
  `}

  ${breakpoints.tabletMax`
    width: 100%;
    height: 60vw;
    max-height: 540px;
    margin-top: 30px;
    border-radius: 40px;

    .swiper, iframe, .gatsby-image-wrapper {
      border-radius: 40px;
    }
  `}

  ${breakpoints.phoneMax`
    border-radius: 30px;

    .swiper, iframe, .gatsby-image-wrapper {
      border-radius: 30px;
    }
  `}
  
  .gatsby-image-wrapper, iframe {
    width: 100%;
    height: 100%;
  }

  iframe {
    border: 0;
  }
`;

const StyledTitle = styled.h2`
  font-size: 4.8rem;
  font-weight: 700;
  margin: 0 0 30px;

  span {
    text-decoration: none !important;
    color: ${({ theme }) => theme.primary};
  }

  ${breakpoints.laptopMax`
    font-size: 3.6rem;
  `}

  ${breakpoints.phoneMax`
      font-size: 2.4rem;
      line-height: 1.5;
      margin: 0 0 20px;
  `}
`;

const StyledDescription = styled.p`
  font-size: 1.8rem;
  ${wysiwygStyles};
  line-height: 1.8;

  :first-of-type {
    margin-bottom: 20px;
  }

  ${breakpoints.laptopMax`
    font-size: 1.6rem;
  `}
`;

const StyledSeeMoreButton = styled(SeeMoreButton)`
  margin-top: 20px;
  margin-left: auto;

  ${breakpoints.phoneMax`
    margin-top: 10px;
  `}
`;

const StyledFramedButton = styled(Button)`
  width: fit-content;
  margin-top: 20px;
  margin-right: auto;
  margin-bottom: 40px;

  ${breakpoints.phoneMax`
    margin-top: 10px;
  `}
`;

export const UniversalSection: FC<Props> = ({
  title,
  description,
  video,
  images,
  fromRight,
  className,
  btnText,
  btnTo,
  galleryId,
  imageOptions,
  dotsReverseColor,
  btnType,
}) => (
  <StyledWrapper $fromRight={fromRight} className={className}>
    <StyledLeftWrapper $fromRight={fromRight}>
      {title && <StyledTitle>{HtmlParser(title)}</StyledTitle>}
      {description.map((item) => (
        <StyledDescription key={item}>{HtmlParser(item)}</StyledDescription>
      ))}

      {btnText &&
        (btnType === "framed" ? (
          /*@ts-ignore*/
          <StyledFramedButton forwardedAs={Link} to={btnTo} secondary>
            {btnText}
          </StyledFramedButton>
        ) : (
          <StyledSeeMoreButton to={btnTo}>{btnText}</StyledSeeMoreButton>
        ))}
    </StyledLeftWrapper>

    <StyledRightWrapper>
      {images &&
        (images.length === 1 ? (
          <GatsbyImage
            image={getImage(images[0])}
            alt={title}
            {...imageOptions}
          />
        ) : (
          <Gallery
            id={galleryId}
            items={images}
            width="100%"
            height="100%"
            dotsReverseColor={dotsReverseColor}
          />
        ))}
      {video && <iframe src={video} allowFullScreen />}
    </StyledRightWrapper>
  </StyledWrapper>
);

interface Props {
  title?: string;
  description: string[];
  video?: string;
  images?: ImageDataLike[];
  fromRight?: true;
  className?: string;
  btnText?: string;
  btnTo?: string;
  btnType?: "unframed" | "framed";
  galleryId?: number;
  imageOptions?: {
    objectFit?: "cover" | "fill" | "contain" | "none" | "scale-down";
  };
  dotsReverseColor?: boolean;
}
