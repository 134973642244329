import React, { FC } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { cssEaseOutExpo } from "@theme/easing";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { breakpoints } from "@theme/breakpoints";

const StyledWrapper = styled(Link)`
  width: 48%;
  height: 28vw;
  max-height: 440px;
  border-radius: 40px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  padding: 40px;
  transform: translateZ(0);

  ${breakpoints.tabletMax`
    width: 100%;
    height:50vw;
    max-height: 700px;
  `}

  ${breakpoints.phoneMax`
    padding: 20px;
    border-radius: 20px;
    height: 60vw;
  `}
`;

const StyledImage = styled(GatsbyImage)`
  position: absolute !important;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: transform 1s ${cssEaseOutExpo};

  ${StyledWrapper}:hover & {
    transform: scale(1.1);
  }
`;

const StyledOverlay = styled.span`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 21.16%,
    rgba(0, 0, 0, 0.4) 50.18%,
    rgba(0, 0, 0, 0.1) 82.39%
  );
`;

const StyledTitle = styled.h2`
  position: relative;
  z-index: 1;
  font-size: 3.6rem;
  font-weight: 700;
  text-align: center;
  color: #fff;

  ${breakpoints.phoneMax`
    font-size: 2.4rem;
  `}
`;

export const OfferCard: FC<Props> = ({ image, title, className, to }) => {
  const { originalPath } = useI18next();

  return (
    <StyledWrapper className={className} to={to} state={{ from: originalPath }}>
      <StyledImage image={getImage(image)} alt={title} />
      <StyledOverlay />
      <StyledTitle>{title}</StyledTitle>
    </StyledWrapper>
  );
};

interface Props {
  title: string;
  image: ImageDataLike;
  className?: string;
  to: string;
}
