import React, { FC } from "react";
import styled, { css } from "styled-components";
import { stylesWrapper, wysiwygStyles } from "@theme/commonStyles";
import { breakpoints } from "@theme/breakpoints";
import HtmlParser from "react-html-parser";

const StyledWrapper = styled.section`
  ${stylesWrapper};
  margin-top: 140px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  ${breakpoints.tabletMax`
    flex-direction: column;
    max-width: 800px;
    margin-top: 100px;
  `}

  ${breakpoints.phoneMax`
    margin-top: 80px;
  `}
`;

const columnStyles = css`
  max-width: 500px;
  width: 45%;

  ${breakpoints.tabletMax`
    max-width: unset;
    width: 100%;
  `};
`;

const StyledLeftColumn = styled.div`
  ${columnStyles};
  margin-right: 50px;

  ${breakpoints.tabletMax`
    max-width: unset;
    margin-right: 0;
  `};
`;

const StyledRightColumn = styled.div`
  ${columnStyles};
`;

const StyledParagraph = styled.p`
  font-size: 1.8rem;
  ${wysiwygStyles};
  line-height: 1.8;

  :first-of-type {
    margin-bottom: 20px;
  }

  ${breakpoints.laptopMax`
    font-size: 1.6rem;
  `}
`;

export const TwoColumnsSection: FC<Props> = ({ leftColumn, rightColumn }) => (
  <StyledWrapper>
    <StyledLeftColumn>
      {leftColumn.map((item) => (
        <StyledParagraph key={item}>{HtmlParser(item)}</StyledParagraph>
      ))}
    </StyledLeftColumn>
    <StyledRightColumn>
      {rightColumn.map((item) => (
        <StyledParagraph key={item}>{HtmlParser(item)}</StyledParagraph>
      ))}
    </StyledRightColumn>
  </StyledWrapper>
);

interface Props {
  leftColumn: string[];
  rightColumn: string[];
}
